.speciality___Gp-8L {
  height: 626px;
  width: 100%;
  background: #fff;
}
.speciality___Gp-8L .title___1tkil {
  text-align: center;
  height: 50px;
  font-size: 36px;
  font-weight: 400;
  color: #212224;
  line-height: 50px;
  box-sizing: content-box;
  padding-top: 78px;
  margin-bottom: 46px;
}
.speciality___Gp-8L .center___1ptda {
  width: 1180px;
  margin: 0 auto;
  display: flex;
  flex-flow: row nowrap;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.15);
}
.speciality___Gp-8L .center___1ptda .box___3wKMc {
  height: 388px;
  text-align: center;
  padding: 58px 49px 0 49px;
}
.speciality___Gp-8L .center___1ptda .box___3wKMc img {
  width: 64px;
  height: 64px;
}
.speciality___Gp-8L .center___1ptda .box___3wKMc .subtitle___3sgBp {
  height: 22px;
  font-size: 16px;
  font-weight: 400;
  color: #212224;
  line-height: 22px;
  margin-top: 25px;
  margin-bottom: 16px;
}
.speciality___Gp-8L .center___1ptda .box___3wKMc .text___3FLVZ {
  width: 198px;
  font-size: 13px;
  font-weight: 400;
  color: #9FA3A7;
  line-height: 20px;
}
.speciality___Gp-8L .center___1ptda .box___3wKMc + .box___3wKMc {
  border-left: 1px solid #CFD6DF;
}
