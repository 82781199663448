.banner___2F9FX .box___2tyD4 {
  width: 100%;
  height: 480px;
  background: linear-gradient(180deg, #0E2035 0%, #0F1727 100%);
}
.banner___2F9FX .box___2tyD4 .content___29-Ig {
  height: 480px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto 100%;
}
.banner___2F9FX .box___2tyD4 .content___29-Ig .center___Zmah4 {
  width: 1180px;
  height: 100%;
  margin: 0 auto;
}
.banner___2F9FX .box___2tyD4 .content___29-Ig .center___Zmah4 .title___2bPvF {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  margin-bottom: 28px;
  padding-top: 162px;
  box-sizing: content-box;
}
.banner___2F9FX .box___2tyD4 .content___29-Ig .center___Zmah4 .title___2bPvF span:first-child {
  font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
  font-size: 40px;
  font-weight: 500;
  color: #ffffff;
  line-height: 1;
  margin-right: 20px;
}
.banner___2F9FX .box___2tyD4 .content___29-Ig .center___Zmah4 .title___2bPvF span:last-child {
  align-self: flex-end;
}
.banner___2F9FX .box___2tyD4 .content___29-Ig .center___Zmah4 .desc___OcXhf {
  font-size: 20px;
  font-family: AlibabaPuHuiTi-Regular, AlibabaPuHuiTi;
  font-weight: 400;
  color: #ffffff;
  line-height: 28px;
}
.banner___2F9FX .ant-carousel .slick-dots {
  margin-left: -40% !important;
}
.banner___2F9FX .ant-carousel .slick-dots .slick-active button {
  background: #005fe1;
}
