.cloud-product___AtPNp {
  max-width: 1840px;
  margin: 0 auto;
}
.cloud-product___AtPNp .top___3BmI5 {
  height: 188px;
  background: linear-gradient(180deg, #0e2035 0%, #0f1727 100%);
  font-size: 36px;
  font-weight: 400;
  color: #ffffff;
  line-height: 188px;
  text-align: center;
}
.cloud-product___AtPNp .bottom___3foJK {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}
.cloud-product___AtPNp .bottom___3foJK .imgWrap___2_g5P {
  width: 25%;
  position: relative;
  overflow: hidden;
}
.cloud-product___AtPNp .bottom___3foJK .imgWrap___2_g5P img {
  width: 100%;
  height: auto;
}
.cloud-product___AtPNp .bottom___3foJK .imgWrap___2_g5P .info___1Nalk {
  width: 100%;
  min-height: 250px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  background: rgba(255, 255, 255, 0.5);
  padding: 30px 10% 30px 10%;
  box-sizing: border-box;
  color: #000000;
}
.cloud-product___AtPNp .bottom___3foJK .imgWrap___2_g5P .info___1Nalk .title___13nR_ {
  height: 30px;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 22px;
}
.cloud-product___AtPNp .bottom___3foJK .imgWrap___2_g5P .info___1Nalk .desc___3A9am {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.cloud-product___AtPNp .bottom___3foJK .imgWrap___2_g5P .info___1Nalk:hover {
  background: rgba(0, 95, 225, 0.7);
  color: #fff;
}
