.select-cloud___2AWL- {
  height: 568px;
  background: #fff;
}
.select-cloud___2AWL- .title___3ICgj {
  padding-top: 78px;
  padding-bottom: 70px;
  text-align: center;
  height: 50px;
  font-size: 36px;
  font-weight: 400;
  color: #2a313b;
  line-height: 50px;
}
.select-cloud___2AWL- .box___1CocY {
  display: flex;
  flex-flow: row nowrap;
  width: 1180px;
  height: 240px;
  margin: 0 auto;
  justify-content: center;
}
.select-cloud___2AWL- .box___1CocY .img-wrap___1OYWK + .img-wrap___1OYWK {
  margin-left: 8px;
}
.select-cloud___2AWL- .box___1CocY .img-wrap___1OYWK {
  width: 160px;
  height: 240px;
  position: relative;
  transition: all 0.5s;
}
.select-cloud___2AWL- .box___1CocY .img-wrap___1OYWK img {
  width: 160px;
  height: 240px;
}
.select-cloud___2AWL- .box___1CocY .img-wrap___1OYWK .desc___k2cRA {
  width: 160px;
  height: 240px;
  position: absolute;
  left: 0;
  top: 0;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
}
.select-cloud___2AWL- .box___1CocY .img-wrap___1OYWK .desc___k2cRA .textWrap___2_B2Y {
  width: 100%;
  font-size: 18px;
  font-weight: 500;
}
.select-cloud___2AWL- .box___1CocY .hover-desc___2tIMI {
  display: none;
}
.select-cloud___2AWL- .box___1CocY .img-wrap___1OYWK:hover {
  width: 340px;
}
.select-cloud___2AWL- .box___1CocY .img-wrap___1OYWK:hover img {
  display: none;
}
.select-cloud___2AWL- .box___1CocY .img-wrap___1OYWK:hover .desc___k2cRA {
  display: none;
}
.select-cloud___2AWL- .box___1CocY .img-wrap___1OYWK:hover .hover-desc___2tIMI {
  display: block;
  width: 340px;
  height: 100%;
  background: #005fe1;
  position: absolute;
  left: 0px;
  top: 0px;
  color: #fff;
  box-sizing: border-box;
  padding: 25px;
}
.select-cloud___2AWL- .box___1CocY .img-wrap___1OYWK:hover .hover-desc___2tIMI div:first-child {
  height: 25px;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  line-height: 25px;
  margin-bottom: 10px;
}
.select-cloud___2AWL- .box___1CocY .img-wrap___1OYWK:hover .hover-desc___2tIMI div:nth-child(2) {
  height: 2px;
  width: 24px;
  background: #fff;
  margin-bottom: 52px;
}
