.practice___Yxr7R {
  padding: 78px 0px;
  background: #fff;
}
.practice___Yxr7R .title___2t13C {
  height: 50px;
  font-size: 36px;
  color: #2a313b;
  line-height: 50px;
  text-align: center;
  margin-bottom: 62px;
}
.practice___Yxr7R .content___1Ryp3 {
  width: 1180px;
  margin: 0 auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
.practice___Yxr7R .content___1Ryp3 .item___vze5p {
  width: 220px;
  position: relative;
}
.practice___Yxr7R .content___1Ryp3 .item___vze5p img {
  width: 100%;
}
.practice___Yxr7R .content___1Ryp3 .item___vze5p .desc___1XMZu {
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 64px;
  width: 100%;
  text-align: center;
  font-size: 20px;
  color: #fff;
  line-height: 54px;
  background: rgba(41, 51, 65, 0.5);
}
