.info-card___2f7qK {
  width: 380px;
  height: 160px;
  background: #ffffff;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  padding: 25px 20px 25px 25px;
  margin-top: 20px;
  background-image: url('/images/decoration.png');
  background-repeat: no-repeat;
  background-position: right -1px top -1px;
}
.info-card___2f7qK div:first-child {
  height: 25px;
  font-size: 18px;
  color: #212224;
  line-height: 25px;
  margin-bottom: 25px;
}
.info-card___2f7qK div:last-child {
  font-size: 14px;
  color: #212224;
  line-height: 20px;
}
