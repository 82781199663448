.join-flow___2lmvq {
  background: #fff;
  padding: 78px 0px 156px 0;
}
.join-flow___2lmvq .title___17vyl {
  height: 50px;
  font-size: 36px;
  font-weight: 400;
  color: #2a313b;
  line-height: 50px;
  text-align: center;
  margin-bottom: 62px;
}
.join-flow___2lmvq .content___1dxny {
  width: 1020px;
  margin: 0 auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}
.join-flow___2lmvq .content___1dxny .wrap___jlI2w {
  width: 120px;
}
.join-flow___2lmvq .content___1dxny .wrap___jlI2w img {
  margin-bottom: 24px;
  width: 120px;
  height: 120px;
}
.join-flow___2lmvq .content___1dxny .wrap___jlI2w div {
  font-size: 18px;
  text-align: center;
}
.join-flow___2lmvq .content___1dxny .arrow___1R6b5 {
  width: 96px !important;
  margin-left: 4px;
  margin-top: -12px;
}
