.programAdvantages___3_YpA {
  height: 556px;
  background: linear-gradient(360deg, #ffffff 0%, #e0e8ee 100%);
}
.programAdvantages___3_YpA .header___D5aZG {
  text-align: center;
  padding-top: 100px;
}
.programAdvantages___3_YpA .header___D5aZG h3 {
  font-size: 36px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #212224;
  line-height: 50px;
  padding-bottom: 8px;
}
.programAdvantages___3_YpA .header___D5aZG span {
  padding-top: 164px;
}
.programAdvantages___3_YpA .header___D5aZG p {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.53);
  line-height: 24px;
}
.programAdvantages___3_YpA .content___1xrN4 {
  width: 1180px;
  height: 100%;
  margin: 64px auto 0;
}
.programAdvantages___3_YpA .content___1xrN4 ul {
  margin-right: -40px;
}
.programAdvantages___3_YpA .content___1xrN4 .item___3bA06 {
  float: left;
  width: 265px;
  height: 210px;
  margin-right: 40px;
  text-align: center;
}
.programAdvantages___3_YpA .content___1xrN4 .item___3bA06 img {
  width: 68px;
  height: 68px;
}
.programAdvantages___3_YpA .content___1xrN4 .item___3bA06 .title___2zl-C {
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 24px;
  margin-top: 24px;
}
.programAdvantages___3_YpA .content___1xrN4 .item___3bA06 .describe___1aRaZ {
  display: inline-block;
  width: 126px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #838383;
  line-height: 24px;
  margin-top: 12px;
}
