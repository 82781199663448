.achievement___P0Zoj {
  width: 100%;
  background: #fff;
}
.achievement___P0Zoj .center___3ExJE {
  width: 1180px;
  margin: 0 auto;
  padding: 78px 0 120px 0;
}
.achievement___P0Zoj .center___3ExJE .title___3rF85 {
  height: 50px;
  font-size: 36px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2a313b;
  line-height: 50px;
  text-align: center;
  margin-bottom: 64px;
}
.achievement___P0Zoj .center___3ExJE .content___LXev1 .logo___3pWV9 {
  width: 96px !important;
  height: 96px !important;
}
.achievement___P0Zoj .center___3ExJE .slick-arrow {
  width: 40px;
  height: 40px;
  z-index: 100;
}
.achievement___P0Zoj .center___3ExJE .slick-prev {
  left: -60px;
  background: url('/images/leftarrow_dis.svg');
}
.achievement___P0Zoj .center___3ExJE .slick-next {
  right: -60px;
  background: url('/images/rightarrow_dis.svg');
}
.achievement___P0Zoj .center___3ExJE .slick-dots {
  display: none !important;
}
