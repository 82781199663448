.banner___16c2T {
  height: 480px;
  background: linear-gradient(180deg, #0e2035 0%, #0f1727 100%);
}
.banner___16c2T .center___2Odra {
  width: 1180px;
  height: 480px;
  margin: 0 auto;
  background: url('/images/apaas-banner.png') no-repeat;
  background-position: center center;
  background-size: auto 100%;
}
.banner___16c2T .center___2Odra .title___2yPcr {
  height: 56px;
  font-size: 36px;
  font-weight: 400;
  color: #ffffff;
  line-height: 56px;
  box-sizing: content-box;
  padding-top: 130px;
  margin-bottom: 6px;
}
.banner___16c2T .center___2Odra .intro___BG6fe {
  width: 600px;
  height: 96px;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
  line-height: 24px;
  margin-bottom: 32px;
}
