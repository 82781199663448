.SupOS___XoBWk {
  min-height: 390px;
  background: #1170ff;
  position: relative;
}
.SupOS___XoBWk .center___2K4za {
  width: 1080px;
  margin: 0 auto;
  position: relative;
}
.SupOS___XoBWk .center___2K4za .img___3b-3S {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
  margin-top: 32px;
}
.SupOS___XoBWk .center___2K4za .title___15PS9 {
  font-size: 28px;
  color: #ffffff;
  letter-spacing: 0.21px;
  padding-top: 76px;
}
.SupOS___XoBWk .center___2K4za .introduce___2p2cS {
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 0.13px;
  text-align: left;
  line-height: 26px;
  margin-top: 24px;
}
.SupOS___XoBWk .itemWrap___3IC5i {
  display: flex;
  width: 1080px;
  height: 222px;
  box-sizing: border-box;
  background: #fff;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -111px;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-right: none;
}
.SupOS___XoBWk .itemWrap___3IC5i .item___2c7rV {
  width: 270px;
  box-sizing: border-box;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  text-align: center;
  padding-top: 36px;
}
.SupOS___XoBWk .itemWrap___3IC5i .item___2c7rV .icon___BOasb {
  width: 50px;
  height: 50px;
}
.SupOS___XoBWk .itemWrap___3IC5i .item___2c7rV .text___3EjvT {
  width: 170px;
  margin: 0 auto;
  margin-top: 20px;
  font-size: 14px;
  color: #212224;
  letter-spacing: 0.13px;
  text-align: center;
  line-height: 24px;
}
.threeLay___33wIk {
  min-height: 745px;
  padding-top: 174px;
  text-align: center;
}
.threeLay___33wIk .title___15PS9 {
  font-size: 28px;
  color: #212224;
  letter-spacing: 0;
  text-align: center;
  margin-bottom: 56px;
}
.threeLay___33wIk .content___2XtRH {
  width: 830px;
  height: 390px;
  margin: 0 auto;
  padding-left: 140px;
  background: url('/images/icon_three_lay.png') no-repeat;
}
.threeLay___33wIk .content___2XtRH .lay___2ZJlW {
  display: flex;
  flex-flow: row nowrap;
  text-align: left;
  margin-bottom: 46px;
  padding-top: 10px;
}
.threeLay___33wIk .content___2XtRH .lay___2ZJlW .left___SRDr8 {
  font-size: 50px;
  margin-right: 58px;
}
.threeLay___33wIk .content___2XtRH .lay___2ZJlW .left___SRDr8 img {
  width: 55px;
  height: 55px;
}
.threeLay___33wIk .content___2XtRH .lay___2ZJlW .right___2ONvf {
  padding-top: 10px;
}
.threeLay___33wIk .content___2XtRH .lay___2ZJlW .right___2ONvf .headline___5GkSv {
  font-size: 20px;
  color: #212224;
  letter-spacing: 0;
}
.threeLay___33wIk .content___2XtRH .lay___2ZJlW .right___2ONvf .detailText___3SwxS {
  width: 450px;
  font-size: 14px;
  color: #212224;
  letter-spacing: 0;
}
.constitute___3EhbC {
  min-height: 620px;
  padding-top: 80px;
  text-align: center;
  background: #fff;
}
.constitute___3EhbC .title___15PS9 {
  font-size: 28px;
  color: #212224;
  letter-spacing: 0;
  text-align: center;
  margin-bottom: 60px;
}
.constitute___3EhbC .constitute-center___2GJ1j {
  width: 1100px;
  margin: 0 auto;
  box-sizing: border-box;
}
.constitute___3EhbC .constitute-center___2GJ1j .first___tgij7 {
  font-size: 18px;
  color: #212224;
  letter-spacing: 0;
  margin-bottom: 10px;
  font-weight: 550;
}
.constitute___3EhbC .constitute-center___2GJ1j .second___2IxDw {
  font-size: 14px;
  color: #181818;
  letter-spacing: 0;
}
.constitute___3EhbC .constitute-center___2GJ1j .upItem___2Luq4 {
  display: inline-block;
  width: 270px;
  height: 180px;
  margin-right: 5px;
  margin-bottom: 8px;
  text-align: left;
  padding: 25px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  overflow: hidden;
}
