.speciality___2366Q {
  min-height: 440px;
  text-align: center;
}
.speciality___2366Q .title___1lgXP {
  font-size: 28px;
  color: #212224;
  letter-spacing: 0;
  text-align: center;
  margin-top: 80px;
  margin-bottom: 46px;
}
.speciality___2366Q .content-box___QSUEq {
  width: 1081px /* 1080/100 */;
  display: flex;
  flex-flow: row wrap;
  margin: 0 auto;
  box-sizing: border-box;
  background: #fff;
  border-left: 1px solid #f2f2f2;
  border-top: 1px solid #f2f2f2;
}
.speciality___2366Q .content-box___QSUEq .item___1EId2 {
  width: 270px /* 270/100 */;
  height: 220px /* 200/100 */;
  box-sizing: border-box;
  border-right: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
}
.speciality___2366Q .content-box___QSUEq .item___1EId2 .icon___1E79X {
  font-size: 60px;
  margin-top: 38px;
}
.speciality___2366Q .content-box___QSUEq .item___1EId2 .text___3hOmd {
  font-size: 16px;
  color: #212224;
  letter-spacing: 0.12px;
  text-align: center;
  margin-top: 40px;
}
.speciality___2366Q .content-box___QSUEq .item___1EId2 .straitness___i3u4B {
  width: 96px;
  margin: 0 auto;
  margin-top: 24px;
}
