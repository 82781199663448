.painPoints___3teaR {
  height: 906px;
  background: url(/images/painPointBg.png) no-repeat;
  background-size: 100% 100%;
}
.painPoints___3teaR .header___3mVfu {
  text-align: center;
  padding-top: 50px;
}
.painPoints___3teaR .header___3mVfu h3 {
  font-size: 36px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #212224;
  line-height: 50px;
  padding-bottom: 8px;
}
.painPoints___3teaR .header___3mVfu span {
  padding-top: 114px;
}
.painPoints___3teaR .header___3mVfu p {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.53);
  line-height: 24px;
}
.painPoints___3teaR .content___5cGQi {
  width: 1181px;
  margin: 48px auto 0;
  box-shadow: 5px 15px 50px 0px rgba(127, 143, 164, 0.1);
}
.painPoints___3teaR .content___5cGQi ul {
  margin-right: -1px;
  margin-bottom: -1px;
}
.painPoints___3teaR .content___5cGQi .card___2RpKt {
  float: left;
  width: 393px;
  height: 288px;
  margin-right: 1px;
  margin-bottom: 1px;
  background-color: #fff;
  text-align: center;
  padding-top: 61px;
}
.painPoints___3teaR .content___5cGQi .card___2RpKt img {
  width: 68px;
  height: 68px;
}
.painPoints___3teaR .content___5cGQi .card___2RpKt .title___2XRXX {
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #000000;
  line-height: 24px;
  margin-top: 24px;
}
.painPoints___3teaR .content___5cGQi .card___2RpKt .describe___2-5-K {
  display: inline-block;
  width: 324px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #838383;
  line-height: 24px;
  margin-top: 12px;
}
