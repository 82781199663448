.banner___3jxis {
  background: linear-gradient(180deg, #0e2035 0%, #0f1727 100%);
  height: 480px;
}
.banner___3jxis .center___vBxS7 {
  width: 1200px;
  height: 480px;
  margin: 0 auto;
  background-image: url('/images/cloud-introduce-banner-1.png');
}
.banner___3jxis .center___vBxS7 .content___2Xa5C {
  text-align: center;
  padding-top: 174px;
  font-size: 40px;
  font-family: AlibabaPuHuiTi-Medium, AlibabaPuHuiTi;
  font-weight: 500;
  color: #ffffff;
  line-height: 56px;
}
