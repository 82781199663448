.build-ecological___27ybO {
  min-height: 480px;
  background: linear-gradient(360deg, #ffffff 0%, #e0e8ee 100%);
  text-align: center;
}
.build-ecological___27ybO .center___1pCf9 {
  width: 1060px;
  margin: 0 auto;
}
.build-ecological___27ybO .center___1pCf9 .header___3xfQj {
  font-size: 28px;
  color: #212224;
  letter-spacing: 0;
  text-align: center;
  padding-top: 100px;
  margin-bottom: 8px;
}
.build-ecological___27ybO .center___1pCf9 .desc___3HPVy {
  height: 24px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.53);
  line-height: 24px;
  margin-bottom: 64px;
}
.build-ecological___27ybO .center___1pCf9 .list___M-3OF {
  display: flex;
  flex-flow: row wrap;
}
.build-ecological___27ybO .center___1pCf9 .list___M-3OF .item___3YdmI {
  width: 25%;
}
.build-ecological___27ybO .center___1pCf9 .list___M-3OF .item___3YdmI img {
  width: 64px;
  height: 64px;
  margin-bottom: 24px;
}
.build-ecological___27ybO .center___1pCf9 .list___M-3OF .item___3YdmI div {
  font-size: 18px;
  font-weight: 600;
  color: #191919;
}
