.ESS-introduce___1rPWw {
  background-image: url('/images/normal-bg.png');
  background-repeat: no-repeat;
  background-position: center center;
  padding-bottom: 80px;
}
.ESS-introduce___1rPWw .content___3e-qt {
  width: 1200px;
  margin: 0 auto;
  padding: 64px 0 0 0;
}
.ESS-introduce___1rPWw .content___3e-qt h4 {
  height: 50px;
  line-height: 50px;
  font-size: 36px;
  font-weight: 400;
  color: #2a313b;
  text-align: center;
  margin-bottom: 60px;
}
.ESS-introduce___1rPWw .content___3e-qt .ulWrap___12y2f {
  width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
.ESS-introduce___1rPWw .content___3e-qt .ulWrap___12y2f li img {
  width: 80px;
  height: 80px;
  margin-bottom: 8px;
}
.ESS-introduce___1rPWw .content___3e-qt .ulWrap___12y2f li div {
  text-align: center;
  font-size: 16px;
  color: #212224;
}
.ESS-introduce___1rPWw .content___3e-qt .info___3VMvp {
  width: 1000px;
  margin: 0 auto;
  text-align: center;
  font-size: 16px;
  margin-top: 50px;
}
.ESS-introduce___1rPWw .content___3e-qt .subtitle___3Hfmk {
  text-align: center;
  height: 40px;
  font-size: 28px;
  font-weight: 400;
  color: #212224;
  line-height: 40px;
  margin: 82px 0 8px 0;
}
.ESS-introduce___1rPWw .content___3e-qt .info-card-wrap___3LFdi {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}
