.platformArchitecture___MvlsU {
  height: 856px;
  background: #fff;
}
.platformArchitecture___MvlsU .header___2zE95 {
  text-align: center;
  padding-top: 100px;
}
.platformArchitecture___MvlsU .header___2zE95 h3 {
  font-size: 36px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #212224;
  line-height: 50px;
  padding-bottom: 8px;
}
.platformArchitecture___MvlsU .header___2zE95 span {
  padding-top: 164px;
}
.platformArchitecture___MvlsU .header___2zE95 p {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.53);
  line-height: 24px;
}
.platformArchitecture___MvlsU .content___3QIHo {
  width: 1142px;
  height: 526px;
  margin: 48px auto 0;
  background: url(/images/platformArchitecture.png) no-repeat;
  background-size: 100% 100%;
}
