.introduce___2zZqc {
  width: 100%;
  min-height: 378px;
  background: #fff;
}
.introduce___2zZqc .center___1NBUy {
  width: 1280px;
  margin: 0 auto;
  padding: 78px 50px 0px 50px;
  position: relative;
}
.introduce___2zZqc .center___1NBUy .decorate___2FkM4 {
  position: absolute;
  left: 0px;
  top: 48px;
}
.introduce___2zZqc .center___1NBUy .decorate___2FkM4 div:first-child {
  width: 16px;
  height: 16px;
  background: #005fe1;
}
.introduce___2zZqc .center___1NBUy .decorate___2FkM4 div:not(:first-child) {
  width: 8px;
  height: 8px;
  background: #005fe1;
  margin-top: 32px;
  margin-left: 4px;
}
.introduce___2zZqc .center___1NBUy .title___2me6b {
  text-align: center;
  margin-bottom: 32px;
  height: 50px;
  font-size: 36px;
  font-weight: 400;
  color: #2a313b;
  line-height: 50px;
}
.introduce___2zZqc .center___1NBUy .info___1uhuc {
  font-size: 14px;
  font-weight: 400;
  color: #212224;
  line-height: 20px;
}
