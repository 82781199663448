.html___3fvA6 {
  height: auto;
}
.page-aPaaS___3rlKZ #tabBox___2JCLo {
  height: 67px;
  width: 100%;
}
.page-aPaaS___3rlKZ .tabWrap___faFRA {
  background-color: #fff;
  border-bottom: 1px solid #f0f1f5;
  box-shadow: 0 5px 20px -5px rgba(0, 0, 0, 0.1);
  position: relative;
}
.page-aPaaS___3rlKZ .tabWrapFixed___2SYrW {
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #f0f1f5;
  box-shadow: 0 5px 20px -5px rgba(0, 0, 0, 0.1);
  position: fixed;
  left: 0;
  top: 60px;
  z-index: 1;
}
.page-aPaaS___3rlKZ .tabContent___1ym1i {
  display: flex;
  width: 1180px;
  height: 66px;
  align-items: center;
  margin: auto;
}
.page-aPaaS___3rlKZ .tabItem___2Cjrl {
  background: #fff;
  border-radius: 6px;
  border: 1px solid #dedede;
  padding: 8px 10px;
  cursor: pointer;
  margin-right: 10px;
}
.page-aPaaS___3rlKZ .tabItemActive___30nEL {
  border-radius: 6px;
  border: 1px solid #dedede;
  padding: 8px 10px;
  cursor: pointer;
  margin-right: 10px;
  font-weight: bold;
  color: #000;
  background-color: #f1f1f1;
}
.page-aPaaS___3rlKZ .appasTabs___PjJ5S {
  background: #fff;
  height: 66px;
  box-shadow: 0px 2px 4px 0px rgba(184, 193, 203, 0.2);
  width: 100%;
  position: relative;
}
.page-aPaaS___3rlKZ .appasTabs___PjJ5S .ant-tabs-nav {
  margin-bottom: 0;
  padding-left: 20%;
  height: 100%;
}
.page-aPaaS___3rlKZ .appasTabs___PjJ5S .ant-tabs-tab-active .ant-anchor-link-title {
  font-weight: bold;
  color: #005fe1 !important;
}
.page-aPaaS___3rlKZ .appasTabs___PjJ5S .ant-anchor-link {
  padding: 0;
}
.page-aPaaS___3rlKZ .appasTabs___PjJ5S .ant-anchor-link .ant-anchor-link-title {
  height: 66px;
  line-height: 66px;
  font-size: 18px;
  color: #384857;
}
