.ecologicalPartner___xm2xr {
  background: #fff;
}
.ecologicalPartner___xm2xr .header___3miBI {
  text-align: center;
  padding-top: 100px;
}
.ecologicalPartner___xm2xr .header___3miBI h3 {
  font-size: 36px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #212224;
  line-height: 50px;
  padding-bottom: 8px;
}
.ecologicalPartner___xm2xr .header___3miBI span {
  padding-top: 164px;
}
.ecologicalPartner___xm2xr .header___3miBI p {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.53);
  line-height: 24px;
}
.ecologicalPartner___xm2xr .header___3miBI a {
  color: #005fe1;
  margin-left: 8px;
}
.ecologicalPartner___xm2xr .content___MXJ-p {
  width: 1182px;
  height: 302px;
  margin: 48px auto 0;
}
.ecologicalPartner___xm2xr .content___MXJ-p ul {
  margin-right: -28px;
}
.ecologicalPartner___xm2xr .content___MXJ-p .card___147tg {
  float: left;
  width: 214px;
  height: 86px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid rgba(127, 143, 164, 0.3);
  margin-right: 28px;
  margin-bottom: 28px;
}
.ecologicalPartner___xm2xr .content___MXJ-p .card___147tg img {
  width: 100%;
  height: 100%;
}
