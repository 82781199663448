.tag___26Jna {
  display: inline-block;
  min-width: 67px;
  height: 24px;
  background: #005fe1;
  text-align: center;
  line-height: 24px;
  font-size: 14px;
  color: #fff;
}
.gradient___3kQWB {
  background: linear-gradient(316deg, #005fe1 0%, #2da8ff 100%);
  border-radius: 2px;
}
