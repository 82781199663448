.resource-bundle___3cFYL {
  background: #fff;
}
.resource-bundle___3cFYL .content___3Ce1o {
  min-height: 400px;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  padding: 30px 80px 64px 80px;
}
.resource-bundle___3cFYL .content___3Ce1o .ant-spin {
  margin: 0 auto;
}
