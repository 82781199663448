.advantage___1gAgg {
  height: 1025px;
  background: url(/images/program-value-bg.png) no-repeat;
  background-size: 100% 100%;
}
.advantage___1gAgg .header___A56l4 {
  font-size: 28px;
  color: #212224;
  letter-spacing: 0;
  text-align: center;
  padding-top: 50px;
  margin-bottom: 48px;
}
.advantage___1gAgg .header___A56l4 h3 {
  padding-bottom: 8px;
}
.advantage___1gAgg .header___A56l4 p {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.53);
  line-height: 24px;
}
.advantage___1gAgg .list___3SIfr {
  width: 1180px;
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}
.advantage___1gAgg .list___3SIfr .item___1n0pq {
  width: 360px;
  margin-bottom: 50px;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.12);
  border-radius: 0px 0px 4px 4px;
  position: relative;
  overflow: hidden;
}
.advantage___1gAgg .list___3SIfr .item___1n0pq img {
  width: 100%;
}
.advantage___1gAgg .list___3SIfr .item___1n0pq:hover img {
  transform: scale(1.06);
  transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.advantage___1gAgg .list___3SIfr .item___1n0pq:hover .triangle___1kqYu {
  border-top: 100px solid transparent;
  border-right: 277px solid #fff;
  border-bottom: 32px solid #fff;
  border-left: 302px solid #0f71e2cc;
  transition: border 0.7s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.advantage___1gAgg .list___3SIfr .item___1n0pq .triangle-box___2pPe7 {
  position: absolute;
  top: 180px;
  overflow: hidden;
  height: 56px;
}
.advantage___1gAgg .list___3SIfr .item___1n0pq .triangle___1kqYu {
  width: 0;
  height: 0;
  border-top: 28px solid transparent;
  border-right: 194px solid #fff;
  border-bottom: 28px solid #fff;
  border-left: 171px solid #0f71e2cc;
}
.advantage___1gAgg .list___3SIfr .item___1n0pq .wrap___3lsYN {
  padding: 15px 30px 30px 30px;
}
.advantage___1gAgg .list___3SIfr .item___1n0pq .wrap___3lsYN .title___G0sbX {
  height: 30px;
  font-size: 22px;
  font-weight: 600;
  color: #191919;
  line-height: 30px;
  margin-bottom: 6px;
}
.advantage___1gAgg .list___3SIfr .item___1n0pq .wrap___3lsYN .divide___2oZ17 {
  width: 100px;
  height: 2px;
  background: linear-gradient(270deg, rgba(241, 243, 245, 0.69) 0%, #dfe3e8 100%);
}
.advantage___1gAgg .list___3SIfr .item___1n0pq .wrap___3lsYN p {
  font-size: 18px;
  color: #838383;
  line-height: 26px;
  margin-top: 18px;
}
