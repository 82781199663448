.instanceType___SHkl9 {
  background: #fff;
  width: 100%;
  text-align: center;
  padding-bottom: 20px;
}
.instanceType___SHkl9 .ant-tabs-top .ant-tabs-nav {
  margin: 0 auto;
}
.instanceType___SHkl9 .ant-tabs-top .ant-tabs-nav::before {
  border: none;
}
.instanceType___SHkl9 .ant-tabs-top .ant-tabs-nav .ant-tabs-bar {
  border: none;
}
.instanceType___SHkl9 .title___2ySla {
  font-size: 36px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #212224;
  line-height: 50px;
  letter-spacing: 0;
  text-align: center;
  padding-top: 60px;
  margin-bottom: 34px;
}
.instanceType___SHkl9 .itemWrap___3ORUI {
  width: 1180px;
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin-top: 30px;
  padding: 20px 0;
}
.instanceType___SHkl9 .itemWrap___3ORUI.noCenterFlow___3l8Kn {
  justify-content: flex-start;
}
.instanceType___SHkl9 .itemWrap___3ORUI .item___1qSVc {
  width: 393px;
  height: 244px;
  position: relative;
  padding: 25px;
  text-align: left;
  border-bottom: 1px solid #f2f2f2;
  border-right: 1px solid #f2f2f2;
  cursor: pointer;
}
.instanceType___SHkl9 .itemWrap___3ORUI .item___1qSVc:hover {
  box-shadow: 8px 8px 20px 0px rgba(55, 99, 170, 0.1);
}
.instanceType___SHkl9 .itemWrap___3ORUI .item___1qSVc:hover .imgAndBtn___3geqk .buyBtn___s4gOY {
  display: block;
  height: 40px;
}
.instanceType___SHkl9 .itemWrap___3ORUI .item___1qSVc .imgAndBtn___3geqk {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.instanceType___SHkl9 .itemWrap___3ORUI .item___1qSVc .imgAndBtn___3geqk .img___gxDRi {
  width: 56px;
  height: 52px;
  margin-bottom: 16px;
  object-fit: contain;
}
.instanceType___SHkl9 .itemWrap___3ORUI .item___1qSVc .imgAndBtn___3geqk .buyBtn___s4gOY {
  width: 100px;
  height: 0px;
  background: #005fe1;
  border-radius: 22px;
  display: none;
  transition: all 0.3s linear;
}
.instanceType___SHkl9 .itemWrap___3ORUI .item___1qSVc .productName___3EDKy {
  font-size: 16px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #354052;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.instanceType___SHkl9 .itemWrap___3ORUI .item___1qSVc .detail___2_b1K {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #354052;
  line-height: 20px;
  margin-bottom: 10px;
  height: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.instanceType___SHkl9 .itemWrap___3ORUI .item___1qSVc .proTags___3e67M {
  height: 50px;
  overflow: hidden;
  white-space: pre-wrap;
}
.instanceType___SHkl9 .itemWrap___3ORUI .item___1qSVc .proTags___3e67M .proTag___B1dOu {
  height: 21px;
  line-height: 21px;
  margin-bottom: 4px;
  background: #f2f6fa;
  border-radius: 2px;
  border: 1px solid #e7ebef;
  color: #354052;
}
.instanceType___SHkl9 .lookMore___3yjuQ {
  cursor: pointer;
}
.instanceType___SHkl9 .lookMore___3yjuQ:hover {
  color: #005fe1;
  text-shadow: 0px 0px 1px rgba(0, 95, 225, 0.5);
}
