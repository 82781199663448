.float-header___2nrnQ {
  width: 100%;
  height: 80px;
  background: #212224;
  position: fixed;
  top: 0;
  display: none;
  z-index: 100;
}
.float-header___2nrnQ .center___12WwS {
  width: 1080px;
  margin: 0 auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
.float-header___2nrnQ .center___12WwS .left-content___1Grad {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 80px;
}
.float-header___2nrnQ .center___12WwS .left-content___1Grad .cloud___2xE31 {
  height: 24px;
}
.float-header___2nrnQ .center___12WwS .left-content___1Grad .cloud___2xE31 img {
  width: 140px;
}
.float-header___2nrnQ .center___12WwS .left-content___1Grad .ess___3B5Ud {
  display: inline-block;
  height: 24px;
  line-height: 21px;
  font-size: 19px;
  color: #ffffff;
}
.float-header___2nrnQ .center___12WwS .right___1-0lR {
  margin-top: 20px;
}
.show___2hMU8 {
  display: block;
}
