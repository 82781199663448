.speciality___3sJ3o {
  min-height: 675px;
  text-align: center;
}
.speciality___3sJ3o .title___ecq1Q {
  font-size: 28px;
  color: #212224;
  letter-spacing: 0;
  text-align: center;
  margin-top: 80px;
  margin-bottom: 50px;
}
.speciality___3sJ3o .content-box___3PPMd {
  width: 1077px;
  display: flex;
  flex-flow: row wrap;
  margin: 0 auto;
  background: #fff;
  border-left: 1px solid #f2f2f2;
  border-top: 1px solid #f2f2f2;
}
.speciality___3sJ3o .content-box___3PPMd .item___S1Ref {
  width: 269px;
  height: 200px;
  box-sizing: border-box;
  border-right: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
}
.speciality___3sJ3o .content-box___3PPMd .item___S1Ref .icon___7XuxP {
  width: 60px;
  height: 60px;
  margin-top: 38px;
}
.speciality___3sJ3o .content-box___3PPMd .item___S1Ref .text___K3gUK {
  font-size: 16px;
  color: #212224;
  letter-spacing: 0.12px;
  text-align: center;
  margin-top: 40px;
}
.speciality___3sJ3o .content-box___3PPMd .item___S1Ref .straitness___1vZyN {
  width: 96px;
  margin: 0 auto;
  margin-top: 24px;
}
