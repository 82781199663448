.product-off___166TX {
  width: 100%;
  height: 100%;
  background: #fff;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.product-off___166TX .content___1XU9B img {
  width: 200px;
  height: 200px;
}
.product-off___166TX .content___1XU9B div {
  height: 24px;
  font-size: 18px;
  font-family: MicrosoftYaHei;
  color: #354052;
  line-height: 24px;
}
