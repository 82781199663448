.functionalModule___2NZWE {
  height: 906px;
  background: linear-gradient(180deg, #ffffff 0%, #e0e8ee 100%);
}
.functionalModule___2NZWE .header___2nXjN {
  text-align: center;
  padding-top: 100px;
}
.functionalModule___2NZWE .header___2nXjN h3 {
  font-size: 36px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #212224;
  line-height: 50px;
  padding-bottom: 8px;
}
.functionalModule___2NZWE .header___2nXjN span {
  padding-top: 164px;
}
.functionalModule___2NZWE .header___2nXjN p {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.53);
  line-height: 24px;
}
.functionalModule___2NZWE .content___15PkR {
  width: 1142px;
  height: 526px;
  margin: 48px auto 0;
  background: url(/images/functionalModule.png) no-repeat;
  background-size: 100% 100%;
  box-shadow: 5px 15px 50px 0px rgba(127, 143, 164, 0.1);
}
