.bottom-bar___2YMNk {
  height: 176px;
  background: #333c49;
}
.bottom-bar___2YMNk .center___1p9f0 {
  width: 1180px;
  height: 100%;
  border-bottom: 1px solid #cfd6df;
  margin: 0 auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  box-sizing: border-box;
  padding-top: 64px;
}
.bottom-bar___2YMNk .center___1p9f0 div:first-child {
  height: 40px;
  font-size: 28px;
  font-weight: 400;
  color: #ffffff;
  line-height: 40px;
}
.bottom-bar___2YMNk .center___1p9f0 .ant-btn-primary {
  height: 48px;
  padding: 0 60px;
  font-size: 18px;
  background: #005fe1;
  background-color: #005fe1;
  border-color: #005fe1;
}
