.ECSSpeciality___3JyJm {
  min-height: 675px;
  text-align: center;
}
.ECSSpeciality___3JyJm .title___2KxgI {
  font-size: 28px;
  color: #212224;
  letter-spacing: 0;
  text-align: center;
  padding-top: 80px;
  margin-bottom: 50px;
}
.ECSSpeciality___3JyJm .content-box___3TUAw {
  width: 1081px;
  display: flex;
  flex-flow: row wrap;
  margin: 0 auto;
  box-sizing: border-box;
  background: #fff;
  border-left: 1px solid #f2f2f2;
  border-top: 1px solid #f2f2f2;
}
.ECSSpeciality___3JyJm .content-box___3TUAw .item___2u75s {
  width: 360px;
  height: 200px;
  box-sizing: border-box;
  border-right: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
}
.ECSSpeciality___3JyJm .content-box___3TUAw .item___2u75s .img___1brW4 {
  width: 64px;
  height: 64px;
  margin-top: 38px;
}
.ECSSpeciality___3JyJm .content-box___3TUAw .item___2u75s .text___2NGzy {
  font-size: 16px;
  color: #212224;
  letter-spacing: 0.12px;
  text-align: center;
  margin-top: 40px;
}
.ECSSpeciality___3JyJm .content-box___3TUAw .item___2u75s .straitness___1iukF {
  width: 96px;
  margin: 0 auto;
  margin-top: 24px;
}
