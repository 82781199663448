.solution___1edTO {
  width: 100%;
  height: 674px;
  background: #fff;
  background-image: url('/images/normal-bg.png');
  background-repeat: no-repeat;
  background-position: center bottom;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.15);
}
.solution___1edTO .title___1yJFw {
  height: 50px;
  font-size: 36px;
  font-weight: 400;
  color: #2a313b;
  line-height: 50px;
  padding-top: 78px;
  text-align: center;
  margin-bottom: 62px;
  box-sizing: content-box;
}
.solution___1edTO .content___2wy-F {
  width: 1180px;
  height: 364px;
  margin: 0 auto;
  display: flex;
  flex-flow: row nowrap;
}
.solution___1edTO .content___2wy-F .menu___15hvG {
  background: #E5EFFC;
  height: 100%;
  width: 224px;
  box-sizing: border-box;
  padding-top: 16px;
}
.solution___1edTO .content___2wy-F .menu___15hvG .item___CFrtV {
  height: 64px;
  font-size: 18px;
  font-weight: 500;
  color: rgba(42, 49, 59, 0.7);
  line-height: 64px;
  cursor: pointer;
  box-sizing: border-box;
  padding-left: 25px;
}
.solution___1edTO .content___2wy-F .menu___15hvG .selected___2z-xe {
  color: #2a313b;
  background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0.3) 100%);
  position: relative;
}
.solution___1edTO .content___2wy-F .menu___15hvG .selected___2z-xe::before {
  content: '';
  width: 4px;
  height: 100%;
  background: #005fe1;
  position: absolute;
  left: 0px;
  top: 0px;
}
.solution___1edTO .content___2wy-F .info___3TRE3 {
  width: 956px;
  background: #fff;
  box-sizing: border-box;
  padding: 40px 64px 25px 64px;
}
.solution___1edTO .content___2wy-F .info___3TRE3 div:first-child {
  color: #2a313b;
  height: 26px;
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 32px;
}
.solution___1edTO .content___2wy-F .info___3TRE3 div:nth-child(2) {
  font-size: 14px;
  color: #2a313b;
  line-height: 20px;
}
.solution___1edTO .content___2wy-F .info___3TRE3 .tagWrap___1u9TQ {
  margin-top: 46px;
  height: 106px;
}
.solution___1edTO .content___2wy-F .info___3TRE3 .tagWrap___1u9TQ .tag___q7QvJ {
  height: 36px;
  display: inline-block;
  padding: 0 30px;
  background: #e8eef2;
  border-radius: 18px;
  line-height: 36px;
  font-size: 12px;
  color: rgba(51, 60, 73, 0.7);
  margin-right: 8px;
}
.solution___1edTO .content___2wy-F .info___3TRE3 .tagWrap___1u9TQ .tag___q7QvJ:hover {
  color: #333c49;
}
.solution___1edTO .content___2wy-F .info___3TRE3 .company___L19nT {
  border-top: 1px solid #cfd6df;
  padding-bottom: 22px;
}
