.customerCase___1QINh {
  height: 770px;
  background: #fff;
}
.customerCase___1QINh .header___3Zeua {
  text-align: center;
  padding-top: 50px;
}
.customerCase___1QINh .header___3Zeua h3 {
  font-size: 36px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #212224;
  line-height: 50px;
  padding-bottom: 8px;
}
.customerCase___1QINh .header___3Zeua p {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.53);
  line-height: 24px;
}
.customerCase___1QINh .content___1KXms {
  margin: 48px auto 0;
  position: relative;
}
.customerCase___1QINh .content___1KXms .left-arrow___1V8r4 {
  width: 64px;
  height: 64px;
  background: #fff;
  border-radius: 100%;
  text-align: center;
  z-index: 2;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 64px;
  margin: auto;
  cursor: pointer;
}
.customerCase___1QINh .content___1KXms .left-arrow___1V8r4 img {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  margin: auto;
}
.customerCase___1QINh .content___1KXms .ant-carousel .slick-dots-bottom {
  bottom: 12px;
  margin-left: 371px;
  justify-content: left;
  width: 1180px;
  margin: 0 auto;
}
.customerCase___1QINh .content___1KXms .ant-carousel .slick-dots li.slick-active {
  width: 32px;
}
.customerCase___1QINh .content___1KXms .ant-carousel .slick-dots li {
  width: 24px;
}
.customerCase___1QINh .content___1KXms .ant-carousel .slick-dots li.slick-active button {
  background: #005fe1;
  opacity: 1;
}
.customerCase___1QINh .content___1KXms .ant-carousel .slick-dots li button {
  display: block;
  width: 100%;
  height: 3px;
  padding: 0;
  color: transparent;
  font-size: 0;
  background: #005fe1;
  border: 0;
  border-radius: 1px;
  outline: none;
  cursor: pointer;
  opacity: 0.3;
  transition: all 0.5s;
}
.customerCase___1QINh .content___1KXms .ant-carousel .slick-dots {
  z-index: 9;
}
.customerCase___1QINh .content___1KXms .carousel-item___31ZgF {
  position: relative;
  height: 591px;
  background: linear-gradient(360deg, #f0f3f54d 0%, #e0e8ee73 100%);
}
.customerCase___1QINh .content___1KXms .carousel-item___31ZgF .left___2g4Tv {
  float: left;
  width: 50%;
  height: 591px;
}
.customerCase___1QINh .content___1KXms .carousel-item___31ZgF .left___2g4Tv .top___2cDSf {
  height: 417px;
  padding-top: 64px;
  width: 590px;
  float: right;
}
.customerCase___1QINh .content___1KXms .carousel-item___31ZgF .left___2g4Tv .top___2cDSf h4 {
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #212224;
  line-height: 33px;
  margin-bottom: 40px;
}
.customerCase___1QINh .content___1KXms .carousel-item___31ZgF .left___2g4Tv .top___2cDSf .label___2cnL- {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #212224;
  line-height: 24px;
  counter-reset: index;
  margin-bottom: 12px;
}
.customerCase___1QINh .content___1KXms .carousel-item___31ZgF .left___2g4Tv .top___2cDSf .describe-item___fJQ4o::before {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #212224b3;
  line-height: 24px;
  counter-increment: index;
  content: counter(index) '\3001' attr(describe);
}
.customerCase___1QINh .content___1KXms .carousel-item___31ZgF .left___2g4Tv .bottom___1x64U {
  height: 174px;
  position: absolute;
  right: 780px;
  bottom: 0;
}
.customerCase___1QINh .content___1KXms .carousel-item___31ZgF .left___2g4Tv .bottom___1x64U img {
  float: right;
  width: 296px;
  height: 100%;
}
.customerCase___1QINh .content___1KXms .carousel-item___31ZgF .right___2FIQs {
  display: inline-block;
  width: 50%;
  height: 591px;
}
.customerCase___1QINh .content___1KXms .carousel-item___31ZgF .right___2FIQs img {
  float: right;
  height: 100%;
  width: 780px;
}
