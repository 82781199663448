.apaas-framework___2dJl2 {
  height: 660px;
  background: #fff;
  text-align: center;
}
.apaas-framework___2dJl2 .header___347uh {
  font-size: 36px;
  color: #212224;
  letter-spacing: 0;
  text-align: center;
  padding-top: 50px;
  margin-bottom: 46px;
}
.apaas-framework___2dJl2 img {
  width: 1144px;
}
