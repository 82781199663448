.apaas-framework___1fmfn {
  height: 720px;
  background: #fff;
  text-align: center;
}
.apaas-framework___1fmfn .header___2z-al {
  font-size: 28px;
  color: #212224;
  letter-spacing: 0;
  text-align: center;
  padding-top: 64px;
  margin-bottom: 46px;
}
.apaas-framework___1fmfn img {
  width: 1144px;
}
