.honor-item___1CqXI {
  background: #fff;
  padding: 40px 32px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  background-image: url('/images/medal.png');
  background-repeat: no-repeat;
  background-position: right 10px top 10px;
  position: relative;
}
.honor-item___1CqXI ul li {
  color: #212224;
}
.honor-item___1CqXI ul li span:last-child {
  display: inline-block;
  width: 900px;
  margin-left: 20px;
}
.honor-item___1CqXI ul li + li {
  margin-top: 20px;
}
.honor-item___1CqXI .icon___2hyeZ {
  position: absolute;
  left: 0px;
  top: -55px;
}
