.about-company___3OiUy {
  background: linear-gradient(360deg, #f0f3f5 0%, #e0e8ee 100%);
}
.about-company___3OiUy .center___14PtE {
  width: 1180px;
  margin: 0 auto;
  padding: 76px 0px 116px 0px;
  box-sizing: border-box;
}
.about-company___3OiUy .center___14PtE .title___Rh9N2 {
  text-align: center;
  height: 50px;
  font-size: 36px;
  font-weight: 400;
  color: #2a313b;
  line-height: 50px;
  margin-bottom: 60px;
}
.about-company___3OiUy .center___14PtE .info___3JCHn {
  font-size: 14px;
  font-weight: 400;
  color: #212224;
  line-height: 20px;
}
.about-company___3OiUy .center___14PtE .info___3JCHn p:first-child {
  margin-bottom: 20px;
}
