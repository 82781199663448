.honor___1BI-N {
  background: linear-gradient(360deg, #f0f3f5 0%, #e0e8ee 100%);
}
.honor___1BI-N .center___22EAM {
  width: 1180px;
  margin: 0 auto;
  padding: 78px 0 100px 0;
}
.honor___1BI-N .center___22EAM .title___3157I {
  height: 50px;
  font-size: 36px;
  font-weight: 400;
  color: #2a313b;
  line-height: 50px;
  text-align: center;
  margin-bottom: 60px;
}
.honor___1BI-N .center___22EAM .honor-item-wrap___WCYlZ {
  margin-top: 100px;
}
