.info___2uF9k {
  width: 356px;
  height: 500px;
  background: #edeef3;
  box-sizing: border-box;
  padding: 32px 45px 22px 45px;
  margin: 0 8px 16px 8px;
}
.info___2uF9k .title___25eQP {
  font-size: 20px;
  font-weight: 500;
  color: #212224;
  height: 28px;
  line-height: 28px;
  margin-bottom: 8px;
}
.info___2uF9k .desc___312I6 {
  height: 60px;
  font-size: 12px;
  color: #9fa3a7;
  line-height: 20px;
  margin-bottom: 23px;
}
.info___2uF9k ul {
  margin-bottom: 32px;
  min-height: 152px;
}
.info___2uF9k ul li {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  margin-bottom: 16px;
}
.info___2uF9k ul li .label___2Eenf {
  width: 58px;
  height: 40px;
  line-height: 40px;
  margin-right: 8px;
  font-size: 12px;
}
.info___2uF9k ul li .right___346Bp {
  width: 200px;
}
.info___2uF9k ul li .right___346Bp .ant-select {
  width: 100%;
}
.info___2uF9k ul li .right___346Bp .ant-select .ant-select-selection {
  width: 200px;
  height: 40px;
  border-radius: 8px;
}
.info___2uF9k .divide___yFHsV {
  width: 100%;
  height: 1px;
  background: #c1cacf;
}
.info___2uF9k .price-box___11Bqj {
  margin-top: 22px;
  margin-bottom: 16px;
}
.info___2uF9k .price-box___11Bqj .discount___1Fsuf {
  color: #ff4400;
  font-size: 30px;
  font-weight: 500;
}
.info___2uF9k .price-box___11Bqj .discount___1Fsuf .sub___1I4OE {
  font-size: 20px;
}
.info___2uF9k .price-box___11Bqj .origin___1-TNl {
  font-size: 12px;
  color: #858585;
  height: 16px;
  line-height: 16px;
  text-decoration: line-through;
}
