.banner___84Jer {
  height: 480px;
  background: #CFD8E8;
}
.banner___84Jer .center___2vxg8 {
  height: 480px;
  margin: 0 auto;
  background: url('/images/channel-recruit-banner-1.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto 100%;
  text-align: center;
}
.banner___84Jer .center___2vxg8 .title___YK4yD {
  height: 56px;
  font-size: 40px;
  font-weight: 500;
  color: #ffffff;
  line-height: 56px;
  padding-top: 170px;
}
.banner___84Jer .center___2vxg8 .desc___3Cww- {
  padding-top: 72px;
  height: 28px;
  font-size: 20px;
  font-weight: 400;
  color: #ffffff;
  line-height: 28px;
  margin-bottom: 72px;
}
.banner___84Jer .center___2vxg8 .ant-btn-primary {
  height: 48px;
  padding: 0 60px;
  font-size: 18px;
  background: #005fe1;
  background-color: #005fe1;
  border-color: #005fe1;
}
