.product-promotion___2jcXO {
  height: 224px;
  background: #fff;
  box-sizing: border-box;
  padding-top: 26px;
}
.product-promotion___2jcXO .content___2l9uX {
  width: 1180px;
  margin: 0 auto;
  height: 160px;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.product-promotion___2jcXO .content___2l9uX .box___owEW8 {
  width: 25%;
  height: 100%;
  box-sizing: border-box;
  padding: 20px 0px;
}
.product-promotion___2jcXO .content___2l9uX .box___owEW8 .inner___22Jtr {
  width: 100%;
  height: 100%;
  border-right: 1px solid #CFD6DF;
  text-align: center;
}
.product-promotion___2jcXO .content___2l9uX .box___owEW8 .inner___22Jtr p {
  padding-top: 32px;
  margin-bottom: 16px;
  font-size: 16px;
}
.product-promotion___2jcXO .content___2l9uX .box___owEW8 .inner___22Jtr a {
  color: #005FE1;
}
.product-promotion___2jcXO .content___2l9uX .box___owEW8:hover {
  background: #005FE1;
}
.product-promotion___2jcXO .content___2l9uX .box___owEW8:hover .inner___22Jtr {
  border-right: none;
}
.product-promotion___2jcXO .content___2l9uX .box___owEW8:hover .inner___22Jtr p {
  color: #fff;
}
.product-promotion___2jcXO .content___2l9uX .box___owEW8:hover .inner___22Jtr a {
  color: #fff;
  text-decoration: underline;
}
.product-promotion___2jcXO .content___2l9uX .box___owEW8:hover .inner___22Jtr span {
  color: #fff;
}
.product-promotion___2jcXO .content___2l9uX .box___owEW8:last-child .inner___22Jtr {
  border-right: none;
}
