.exception___2MjaQ {
  display: flex;
  align-items: center;
  height: 80%;
  min-height: 500px;
}
.exception___2MjaQ .imgBlock___3C2qT {
  flex: 0 0 62.5%;
  width: 62.5%;
  padding-right: 152px;
  zoom: 1;
}
.exception___2MjaQ .imgBlock___3C2qT::before,
.exception___2MjaQ .imgBlock___3C2qT::after {
  display: table;
  content: ' ';
}
.exception___2MjaQ .imgBlock___3C2qT::after {
  clear: both;
  height: 0;
  font-size: 0;
  visibility: hidden;
}
.exception___2MjaQ .imgEle___2Z0IG {
  float: right;
  width: 100%;
  max-width: 430px;
  height: 360px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
}
.exception___2MjaQ .content___DGjuP {
  flex: auto;
}
.exception___2MjaQ .content___DGjuP h1 {
  margin-bottom: 24px;
  color: #434e59;
  font-weight: 600;
  font-size: 72px;
  line-height: 72px;
}
.exception___2MjaQ .content___DGjuP .desc___3-qfg {
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.45);
  font-size: 20px;
  line-height: 28px;
}
.exception___2MjaQ .content___DGjuP .actions___3HGa5 button:not(:last-child) {
  margin-right: 8px;
}
@media screen and (max-width: 1200px) {
  .exception___2MjaQ .imgBlock___3C2qT {
    padding-right: 88px;
  }
}
@media screen and (max-width: 576px) {
  .exception___2MjaQ {
    display: block;
    text-align: center;
  }
  .exception___2MjaQ .imgBlock___3C2qT {
    margin: 0 auto 24px;
    padding-right: 0;
  }
}
@media screen and (max-width: 480px) {
  .exception___2MjaQ .imgBlock___3C2qT {
    margin-bottom: -24px;
    overflow: hidden;
  }
}
