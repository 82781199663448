.help___2kbPt {
  min-height: 540px /* 540/100 */;
  background: #fafbfc;
  padding-top: 80px /* 80/100 */;
  text-align: center;
}
.help___2kbPt .title___pZw5G {
  font-size: 28px;
  color: #212224;
  letter-spacing: 0;
  text-align: center;
  margin-bottom: 60px /* 60/100 */;
}
.help___2kbPt .help-center___3IMh8 {
  width: 1100px /* 1100/100 */;
  margin: 0 auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
.help___2kbPt .help-center___3IMh8 .help-item___3ReYd {
  display: block;
  width: 212px /* 212/100 */;
  height: 285px /* 285/100 */;
  position: relative;
}
.help___2kbPt .help-center___3IMh8 .help-item___3ReYd .img___2Oop2 {
  width: 212px /* 212/100 */;
  height: 285px /* 285/100 */;
}
.help___2kbPt .help-center___3IMh8 .help-item___3ReYd .help-detail___1QSwM {
  width: 92px /* 92/100 */;
  height: 110px /* 110/100 */;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  cursor: pointer;
  transition: all 0.5s;
  z-index: 10;
}
.help___2kbPt .help-center___3IMh8 .help-item___3ReYd .mask___296tc {
  width: 212px /* 212/100 */;
  height: 285px /* 285/100 */;
  position: absolute;
  top: 0;
  left: 0;
}
.help___2kbPt .help-center___3IMh8 .help-item___3ReYd .help-icon___24rwC {
  font-size: 60px;
}
.help___2kbPt .help-center___3IMh8 .help-item___3ReYd .help-text___IAV3J {
  margin-top: 24px;
  font-size: 18px;
  color: #ffffff;
  letter-spacing: 0.14px;
  text-align: center;
}
.help___2kbPt .help-center___3IMh8 .help-item___3ReYd:hover .help-detail___1QSwM {
  top: -44px;
}
.help___2kbPt .help-center___3IMh8 .help-item___3ReYd:hover .mask___296tc {
  background: #005fe1;
}
