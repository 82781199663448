.join-us___5ksZA {
  background: #fff;
  padding-top: 78px;
  padding-bottom: 78px;
}
.join-us___5ksZA .why___2Jims {
  text-align: center;
  height: 50px;
  font-size: 36px;
  font-weight: 400;
  color: #2a313b;
  line-height: 50px;
  padding-bottom: 62px;
}
.join-us___5ksZA .wrap___1KDbk {
  width: 1180px;
  margin: 0 auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
.join-us___5ksZA .wrap___1KDbk .reason___jn5W2 {
  width: 335px;
  text-align: center;
}
.join-us___5ksZA .wrap___1KDbk .reason___jn5W2 img {
  width: 160px;
  height: 160px;
  margin-bottom: 40px;
}
.join-us___5ksZA .wrap___1KDbk .reason___jn5W2 .title___3jeu0 {
  margin-bottom: 26px;
  font-size: 18px;
  color: #212224;
  line-height: 25px;
}
.join-us___5ksZA .wrap___1KDbk .reason___jn5W2 .content___3xw3V {
  color: #212224;
  line-height: 20px;
  text-align: left;
}
