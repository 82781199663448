.introduction___2Iw0n {
  width: 100%;
  min-height: 214px;
  background: #fff;
}
.introduction___2Iw0n .center___NbYOi {
  width: 1180px;
  margin: 0 auto;
  display: flex;
  flex-flow: row nowrap;
  box-sizing: border-box;
  overflow: hidden;
}
.introduction___2Iw0n .center___NbYOi .left___2A56k,
.introduction___2Iw0n .center___NbYOi .right___127hf {
  margin-bottom: -999px;
  padding-bottom: 999px;
  padding-top: 56px;
}
.introduction___2Iw0n .center___NbYOi .left___2A56k .tag___3-LJJ,
.introduction___2Iw0n .center___NbYOi .right___127hf .tag___3-LJJ {
  background: linear-gradient(316deg, #005fe1 0%, #2da8ff 100%);
  border-radius: 2px;
}
.introduction___2Iw0n .center___NbYOi .left___2A56k > h4,
.introduction___2Iw0n .center___NbYOi .right___127hf > h4 {
  font-size: 18px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #191919;
  line-height: 18px;
  padding-bottom: 17px;
}
.introduction___2Iw0n .center___NbYOi .left___2A56k ul > li,
.introduction___2Iw0n .center___NbYOi .right___127hf ul > li {
  margin-bottom: 14px;
}
.introduction___2Iw0n .center___NbYOi .left___2A56k ul > li a,
.introduction___2Iw0n .center___NbYOi .right___127hf ul > li a {
  display: inline-block;
  margin-left: 12px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #191919;
}
.introduction___2Iw0n .center___NbYOi .left___2A56k ul > li a:hover,
.introduction___2Iw0n .center___NbYOi .right___127hf ul > li a:hover {
  color: #005fe1;
}
.introduction___2Iw0n .center___NbYOi .left___2A56k {
  flex: 0 0 680px;
  flex-flow: row nowrap;
}
.introduction___2Iw0n .center___NbYOi .right___127hf {
  flex-grow: 1;
  margin-left: 67px;
}
