.page-channelRecruit___UXJTC .introduce___1ezyh {
  height: 160px;
  background: linear-gradient(360deg, #F0F3F5 0%, #E0E8EE 100%);
  box-sizing: border-box;
  padding-top: 48px;
  font-size: 20px;
  line-height: 32px;
}
.page-channelRecruit___UXJTC .introduce___1ezyh .content___3_Sy5 {
  width: 660px;
  margin: 0 auto;
  text-align: center;
}
