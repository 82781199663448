.anchor___3v1qq {
  height: 64px;
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 10;
  box-shadow: -5px 4px 3px 0px #4d7f8f2b;
}
.anchor___3v1qq .labels___29Nh3 {
  height: 100%;
  width: 1180px;
  margin: 0 auto;
}
.anchor___3v1qq .labels___29Nh3 .ant-anchor-wrapper {
  padding-left: 0;
}
.anchor___3v1qq .labels___29Nh3 .ant-anchor {
  padding-left: 0;
}
.anchor___3v1qq .labels___29Nh3 .ant-anchor-ink {
  display: none;
}
.anchor___3v1qq .labels___29Nh3 .ant-anchor-link {
  display: inline-block;
  margin-right: 48px;
  padding: 0;
}
.anchor___3v1qq .labels___29Nh3 .ant-anchor-link-title {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  line-height: 64px;
}
