.certification___1JamT {
  background-image: url('/images/normal-bg.png');
  background-repeat: no-repeat;
  background-position: center bottom 0px;
}
.certification___1JamT .center___25WWH {
  width: 1180px;
  padding: 78px 0 120px 0;
  margin: 0 auto;
}
.certification___1JamT .center___25WWH .title___3NENG {
  height: 50px;
  font-size: 36px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2a313b;
  line-height: 50px;
  text-align: center;
  margin-bottom: 75px;
}
.certification___1JamT .center___25WWH .content___2NBR6 {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  gap: 90px;
}
.certification___1JamT .center___25WWH .content___2NBR6 .cert-item___3SwFm {
  width: 128px;
  text-align: center;
}
.certification___1JamT .center___25WWH .content___2NBR6 .cert-item___3SwFm img {
  width: 128px;
  height: 128px;
  margin-bottom: 8px;
}
.certification___1JamT .center___25WWH .content___2NBR6 .cert-item___3SwFm div {
  height: 22px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #212224;
  line-height: 22px;
}
.whiteBg___20bub {
  background: #fff;
}
