.programValue___2-UDx {
  height: 1096px;
  background: url(/images/program-value-bg.png) no-repeat;
  background-size: 100% 100%;
}
.programValue___2-UDx .header___1Fhj6 {
  text-align: center;
  padding-top: 100px;
}
.programValue___2-UDx .header___1Fhj6 h3 {
  font-size: 36px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #212224;
  line-height: 50px;
  padding-bottom: 8px;
}
.programValue___2-UDx .header___1Fhj6 span {
  padding-top: 164px;
}
.programValue___2-UDx .header___1Fhj6 p {
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.53);
  line-height: 24px;
}
.programValue___2-UDx .content___1upEE {
  width: 1180px;
  margin: 100px auto 0;
  box-shadow: 5px 15px 50px 0px rgba(127, 143, 164, 0.1);
}
.programValue___2-UDx .content___1upEE ul {
  margin-right: -50px;
  margin-bottom: -50px;
}
.programValue___2-UDx .content___1upEE .card___3T9Mo {
  float: left;
  width: 360px;
  height: 370px;
  background: #ffffff;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  text-align: left;
  margin-right: 50px;
  margin-bottom: 50px;
  overflow: hidden;
  position: relative;
}
.programValue___2-UDx .content___1upEE .card___3T9Mo:hover img {
  transform: scale(1.06);
  transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.programValue___2-UDx .content___1upEE .card___3T9Mo:hover .triangle___pv_ds {
  border-top: 100px solid transparent;
  border-right: 277px solid #fff;
  border-bottom: 32px solid #fff;
  border-left: 302px solid #0f71e2cc;
  transition: border 0.7s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.programValue___2-UDx .content___1upEE .card___3T9Mo .triangle-box___2BMLK {
  position: absolute;
  top: 172px;
  overflow: hidden;
  height: 56px;
}
.programValue___2-UDx .content___1upEE .card___3T9Mo .triangle___pv_ds {
  width: 0;
  height: 0;
  border-top: 28px solid transparent;
  border-right: 194px solid #fff;
  border-bottom: 28px solid #fff;
  border-left: 171px solid #0f71e2cc;
}
.programValue___2-UDx .content___1upEE .card___3T9Mo .img-box___12DlJ {
  width: 100%;
  height: 228px;
  overflow: hidden;
}
.programValue___2-UDx .content___1upEE .card___3T9Mo img {
  width: 100%;
  height: 228px;
}
.programValue___2-UDx .content___1upEE .card___3T9Mo .title___1Ju8t {
  padding-left: 30px;
}
.programValue___2-UDx .content___1upEE .card___3T9Mo .title___1Ju8t .num___2Oi9l {
  font-size: 40px;
  font-family: DINAlternate-Bold, DINAlternate;
  font-weight: bold;
  color: #0f71e2;
  line-height: 47px;
  border-bottom: 2px solid;
  border-image: -webkit-linear-gradient(right, rgba(241, 243, 245, 0.69) 0%, #dfe3e8 100%) 1 12;
}
.programValue___2-UDx .content___1upEE .card___3T9Mo .title___1Ju8t .value___3TfiN {
  font-size: 16px;
  margin-left: 8px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #dfe3e8;
  line-height: 22px;
}
.programValue___2-UDx .content___1upEE .card___3T9Mo .describe___FXYPb {
  padding-left: 30px;
  display: inline-block;
  width: 324px;
  margin-top: 18px;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #191919;
  line-height: 26px;
  white-space: pre-line;
}
